import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/rootReducer';
import { AppContext } from '../../../components/context/AppContect';
import telePhoneIcon from '../../../images/icons/telephone-call.svg';
import logo from '../../../images/core/pineapple-logo-black-text.svg';
import styles from './Header.module.css';
import LogoutButton from '../../../components/auth/logout/logout';
// import LanguageSelectorDropdown from '../../../components/appointments/language-selector/LanguageSelector';

const Header = () => {
    const { openViaSlug } = useSelector((state: IRootState) => state.authReducer.data);
    const appContext = useContext(AppContext);

    const { firstName, lastName, photo_url } = appContext.visitor;

    const [showDropdown, setShowDropdown] = useState(false);
    const [profilePicture, setProfilePicture] = useState('');

    useEffect(() => {
        if (photo_url) {
            setProfilePicture(photo_url);
        }
    }, [photo_url]);

    const navigate = useNavigate();

    return (
        <div className={styles.fluid}>
            <div className={`${styles.outerContainer} ${styles.flexContainer}`}>
                <div className={styles.logo}>
                    <Link to="/">
                        <img src={logo} alt="pineapple logo"></img>
                    </Link>
                </div>
                <div className={styles.headerMenu}>
                    {/* <a className={styles.callIcon} href={`https://app.openvia.co/credentials/${openViaSlug}`} target='_blank'>
                        Open Via
                    </a> */}

                    <a className={`${styles.callIcon} ${styles.helpBtn}`} href="tel:+18887511211p800">
                        <img src={telePhoneIcon} alt="call" width={16} height={16} />
                        Help
                    </a>
                    {!!appContext.visitor && (
                        <div className={styles.btnWrapper}>
                            {/* <Button onClick={handleLogout} text={'Logout'} >
                                <img className={styles.icoMargin} src={logoutIcon} alt='logout' width={16} height={16} />
                            </Button> */}
                            {/* <LanguageSelectorDropdown /> */}

                            <div className={styles.userDropdownContainer}>
                                <div
                                    className={`${styles.userProfile} ${profilePicture !== '' ? styles.withProfile : ''}`}
                                    onClick={() => setShowDropdown(!showDropdown)}>
                                    {!!profilePicture ? (
                                        <img src={profilePicture} alt="" width={40} height={40} className={styles.userProfileImage} />
                                    ) : (
                                        <span>{firstName?.charAt(0) + lastName?.charAt(0)}</span>
                                    )}
                                </div>
                                {showDropdown && <div className={styles.backDrop} onClick={() => setShowDropdown(false)}></div>}
                                <div className={`${styles.dropdown} ${showDropdown ? styles.show : ''}`}>
                                    <div className={styles.list}>
                                        <div
                                            className={styles.item}
                                            onClick={() => {
                                                setShowDropdown(false);
                                                navigate('/profile');
                                            }}>
                                            <span>My profile</span>
                                        </div>

                                        <div
                                            className={styles.item}
                                            onClick={() => {
                                                setShowDropdown(false);
                                                navigate('/locations');
                                            }}>
                                            <span>Book An Appointment</span>
                                        </div>

                                        <div
                                            className={styles.item}
                                            onClick={() => {
                                                setShowDropdown(false);
                                                navigate('/appointments');
                                            }}>
                                            <span>My Appointments</span>
                                        </div>

                                        <div
                                            className={styles.item}
                                            onClick={() => {
                                                setShowDropdown(false);
                                                navigate('/change-password');
                                            }}>
                                            <span>Change password</span>
                                        </div>

                                        <LogoutButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;
